import React from "react";

import { Button, Icon, IconButton, Grid, Divider, Card } from "@mui/material";

const Portfolio1 = ({data}) => {


  const {
    list,
    title,
    subtitle
  } = data;

  return (
    <section className="section" id="portfolio1">
      <div className="container">
        <div className="section__header">
          <h2>{title}</h2>
          <p>
            {subtitle}
          </p>
        </div>
        <Grid container spacing={3}>
          {list.map((portfolio, index) => (
            <Grid item md={4} sm={4} key={index}>
              <Card className="relative h-full card" key={index}>
                <img
                  className="w-full"
                  src={portfolio.image.url}
                  alt="portfolio"
                />
                <div className="flex-column justify-between">
                  <div className="px-4 pt-4">
                    <h5 className="m-0 text-16 font-bold">{portfolio.title}</h5>
                    <p className="mb-4">{portfolio.text}</p>
                    <Divider />
                  </div>
                  <div className="px-4 py-2">
                    <IconButton>
                      <Icon>link</Icon>
                    </IconButton>
                  </div>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>

        <div className="text-center pt-9">
          <Button variant="contained" size="large" color="secondary">
            load more
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Portfolio1;
