import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

/**
 * Display posts by cateogry
 */
const Category = () => {

    const params = useParams();
    const [posts, setPosts] = useState<any[]>([]);

    const [{ data, loading }] = useAxios(
        process.env.REACT_APP_API+`/wp/v2/posts?category_slug=${params.category}&acf_format=standard`
    )

    useEffect(() => {
        if(!loading){
            setPosts(data);
        }
    }, [data, loading]);
    
    return(
        <div className="row mt-5 mb-5 pt-5 container">
                {posts.map((singlePost) => {
                        return(
                            <div className="col-md-6 col-lg-4 mt-5 wow fadeInUp" data-wow-delay=".2s" key={singlePost.id}>
                                <div className="blog-grid">
                                    <div className="blog-grid-img position-relative"><img alt="img" src={singlePost.acf.thumbnail.sizes.medium} /></div>
                                    <div className="blog-grid-text p-4">
                                        <h3 className="h5 mb-3"><Link to={singlePost.slug}>{singlePost.title.rendered}</Link></h3>
                                        <p className="display-30">{singlePost.acf.short_content}</p>
                                        <div className="meta meta-style2">
                                            <ul>
                                                <li><i className="fas fa-calendar-alt"></i>{singlePost.date}</li>
                                                <li><a href="#!"><i className="fas fa-user"></i> User</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                )}
        </div>
    );
}

export default Category;
