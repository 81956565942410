import React from "react";
import { SvgIcon } from "@mui/material";

const LinkedinIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 25.905 24.763">
      <path
        id="Path_48"
        data-name="Path 48"
        d="M6.245,3.38A2.9,2.9,0,0,1,3.1,6.265H3.068A2.895,2.895,0,1,1,3.14.491,2.886,2.886,0,0,1,6.245,3.38ZM.326,25.253H5.88V8.547H.326Zm19.186-17.1a5.517,5.517,0,0,0-5.006,2.758v.054h-.036a.27.27,0,0,0,.036-.054V8.549H8.953c.072,1.565,0,16.705,0,16.705h5.553v-9.33a3.75,3.75,0,0,1,.183-1.355,3.05,3.05,0,0,1,2.847-2.033c2.012,0,2.817,1.535,2.817,3.779v8.939H25.9V15.676C25.906,10.541,23.164,8.156,19.511,8.156Z"
        transform="translate(0 -0.491)"
      />
    </SvgIcon>
  );
};

export default LinkedinIcon;
