import axios from 'axios';
import React, { useState } from 'react';
import ValidateToken from '../../helpers/validateToken';
import { Link } from 'react-router-dom';

/**
 * Simple login page
 */
const LoginPage = () => {

    const [notification, setNotification] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    
    if(ValidateToken()){
        document.location.href = '/';
    }

    const submitLogin = (e: any) => {
        e.preventDefault();
        axios.post(process.env.REACT_APP_API+`/jwt-auth/v1/token`, { 
            username,
            password
         })
         .then( function( response ) {
            setNotification('Hello');
            localStorage.setItem("token", response.data.token);
            window.location.reload()
          } )
    
          .catch( function( error ) {
            setNotification('Bad credintials');
            localStorage.setItem("token", "");
            // console.error( 'Error', error.data[0] );
          } );
    }
    

    return(
        <div className="d-flex justify-content-center bg-light pt-5 mt-5">
            <div className="border p-3 m-3 w-25 bg-white">
                <h2 className="text-primary">Login:</h2>
                    <div className="form-group text-secondary my-2">
                        <label htmlFor="exampleInputEmail1">Email address</label>
                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" onChange={(e : any) => setUsername(e.target.value)}/>
                    </div>
                    <div className="form-group text-secondary my-2">
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" onChange={(e : any) => setPassword(e.target.value)}/>
                    </div>
                    <button type="submit" className="btn btn-primary" onClick={submitLogin}>Submit</button>
                    <div className="text-danger mt-2">
                        {notification}
                    </div>
                    <div className="text-info">If you don't have account <Link to="/register"><div className="btn btn-primary mt-1">Register here</div></Link></div>
            </div>
        </div>
    );
}

export default LoginPage;