import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

function TestimonialHomeOne({ data }) {
  const sliderRef = useRef();
  const sliderNext = () => {
    sliderRef.current.slickNext();
  };
  const sliderPrev = () => {
    sliderRef.current.slickPrev();
  };

  const testimonials = data.testimonials.map(({ id, name, date, content }) => ({
    id,
    name,
    date,
    content,
  }));

  return (
    <section
      className={`appie-testimonial-area pt-100 pb-160 `}
      id="testimonial"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div
              className="appie-testimonial-slider"
              style={{ position: "relative" }}
            >
              <span
                className="prev slick-arrow"
                style={{ display: "block" }}
                onClick={sliderNext}
                role="button"
                tabIndex="0"
              >
                <i className="fal fa-arrow-left" />
              </span>
              <Slider ref={sliderRef} dots arrows={false}>
                {testimonials.map(({ id, name, date, content }) => (
                  <div className="appie-testimonial-item text-center" key={id}>
                    <div className="author-info">
                      <img src={data.userImage} alt="" />
                      <h5 className="title">{name}</h5>
                      <span>{date}</span>
                    </div>
                    <div className="text">
                      <p>{content}</p>
                      <ul>
                        {[...Array(5)].map((_, index) => (
                          <li key={index}>
                            <i className="fas fa-star" />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </Slider>
              <span
                onClick={sliderPrev}
                role="button"
                tabIndex="-1"
                className="next slick-arrow"
                style={{ display: "block" }}
              >
                <i className="fal fa-arrow-right" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TestimonialHomeOne;