import React from "react";
import { Link } from "react-router-dom";

function FooterHomeOne({ data }) {
  const socialLinks = [
    { name: "Facebook", icon: "fab fa-facebook-f", url: "#" },
    { name: "Twitter", icon: "fab fa-twitter", url: "#" },
    { name: "Pinterest", icon: "fab fa-pinterest-p", url: "#" },
    { name: "LinkedIn", icon: "fab fa-linkedin-in", url: "#" },
  ];

  const footerNavLinks = [
    { title: "About Us", path: "/about-us" },
    { title: "Service", path: "/Service" },
    { title: "Case Studies", path: "#" },
    { title: "Blog", path: "/news" },
    { title: "Contact", path: "/contact" },
  ];

  const supportLinks = [
    { title: "Community", path: "/about-us" },
    { title: "Resources", path: "#" },
    { title: "Faqs", path: "#" },
    { title: "Privacy Policy", path: "#" },
    { title: "Careers", path: "#" },
  ];

  const contactDetails = [
    { icon: "fal fa-envelope", text: "support@appie.com", url: "#" },
    { icon: "fal fa-phone", text: "+(642) 342 762 44", url: "#" },
    {
      icon: "fal fa-map-marker-alt",
      text: "442 Belle Terre St Floor 7, San Francisco, AV 4206",
      url: "#",
    },
  ];

  const downloadLinks = [
    { icon: "fab fa-apple", text: "Download for iOS", url: "#" },
    { icon: "fab fa-google-play", text: "Download for Android", url: "#" },
  ];

  return (
    <>
      <section className={`appie-footer-area`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-about-widget">
                <div className="logo">
                  <a href="#">
                    <img src={data.logo?.url} alt="" />
                  </a>
                </div>
                <p>{data.footer_about_text}</p>
                <a href={data.read_more_link}>
                  {data.read_more_text} <i className="fal fa-arrow-right" />
                </a>
                <div className="social mt-30">
                  <ul>
                    {socialLinks.map((link) => (
                      <li key={link.name}>
                        <a href={link.url}>
                          <i className={link.icon} />
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="footer-navigation">
                <h4 className="title">{data.company_title}</h4>
                <ul>
                  {footerNavLinks.map((link) => (
                    <li key={link.title}>
                      <Link to={link.path}>{link.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-navigation">
                <h4 className="title">{data.support_title}</h4>
                <ul>
                  {supportLinks.map((link) => (
                    <li key={link.title}>
                      <Link to={link.path}>{link.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-widget-info">
                <h4 className="title">{data.get_in_touch_title}</h4>
                <ul>
                  {contactDetails.map((detail) => (
                    <li key={detail.text}>
                      <a href={detail.url}>
                        <i className={detail.icon} /> {detail.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                <div className="apps-download-btn">
                  <ul>
                    {downloadLinks.map((link) => (
                      <li key={link.text}>
                        <a href={link.url}>
                          <i className={link.icon} /> {link.text}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="copyright-text">
                  <p>{data.copyright_text}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FooterHomeOne;
