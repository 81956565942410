import React, { useRef, useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import parse from "html-react-parser";

function DescriptionProduct({ data }) {
  const [detailsTab, setTab] = useState("review");
  const detailsTabHandler = (e, value) => {
    e.preventDefault();
    setTab(value);
  };

  const { description, reviews } = data;

  return (
    <>
      <div className="shop-details-info-area pt-85 pb-100 ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="shop-details-box">
                <ul
                  className="nav nav-pills mb-35"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      onClick={(e) => detailsTabHandler(e, "des")}
                      className={`nav-link ${
                        detailsTab === "des" ? "active" : ""
                      }`}
                      id="pills-home-tab"
                      data-toggle="pill"
                      href="#pills-home"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Description
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      onClick={(e) => detailsTabHandler(e, "review")}
                      className={`nav-link ${
                        detailsTab === "review" ? "active" : ""
                      }`}
                      id="pills-profile-tab"
                      data-toggle="pill"
                      href="#pills-profile"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Reviews
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className={`tab-pane fade ${
                      detailsTab === "des" ? "show active" : ""
                    }`}
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="shop-description">{parse(description)}</div>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      detailsTab === "review" ? "show  active" : ""
                    }`}
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="review-rating-box">
                      <div className="top-rating-result">
                        <h3 className="title">Reviews</h3>
                        {reviews.map((review, index) => (
                          <div className="rating-result-box">
                            <div className="thumb">
                              <img src={review.image.url} alt="" />
                            </div>
                            <div className="content">
                              <ul>
                                <li>
                                  <i className="fas fa-star"></i>
                                </li>
                                <li>
                                  <i className="fas fa-star"></i>
                                </li>
                                <li>
                                  <i className="fas fa-star"></i>
                                </li>
                                <li>
                                  <i className="fas fa-star"></i>
                                </li>
                                <li>
                                  <i className="fas fa-star-half-alt"></i>
                                </li>
                              </ul>
                              <div className="shop-meta">
                                <div className="author-user-name">
                                  <a href="#">{review.name}</a>
                                </div>
                                <div className="date">
                                  <span>{review.date}</span>
                                </div>
                              </div>
                              <p>{review.review_text}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="review-box">
                        <div className="review-title">
                          <h4 className="title">Add a Review</h4>
                          <p>
                            Your email address will not be published.Required
                            fields are marked*
                          </p>
                        </div>
                        <div className="add-review-star">
                          <span>Rate this product:</span>
                          <ul>
                            <li>
                              <i className="fas fa-star"></i>
                            </li>
                            <li>
                              <i className="fas fa-star"></i>
                            </li>
                            <li>
                              <i className="fal fa-star"></i>
                            </li>
                            <li>
                              <i className="fal fa-star"></i>
                            </li>
                            <li>
                              <i className="fal fa-star"></i>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="rating-form-box">
                        <form action="#">
                          <div className="input-box">
                            <label htmlFor="#">Name *</label>
                            <input type="text" placeholder="Username" />
                          </div>
                          <div className="input-box">
                            <label htmlFor="#">Email *</label>
                            <input type="email" placeholder="E-mail address" />
                          </div>
                          <div className="input-box">
                            <label htmlFor="#">Your review </label>
                            <textarea
                              name="#"
                              id="#"
                              cols="30"
                              rows="10"
                              placeholder="Type you message"
                            ></textarea>
                          </div>
                          <div className="input-box">
                            <div className="condition-check pb-15">
                              <input id="terms-conditions" type="checkbox" />
                              <label htmlFor="terms-conditions">
                                Save my name, email, and website in this browser
                                for the next time I comment
                              </label>
                            </div>
                            <button className="main-btn" type="submit">
                              Submit Review
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DescriptionProduct;
