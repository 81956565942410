import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ palette, ...theme }) => ({
  cardHolder: {
    position: "relative",
    borderRadius: 8,
    overflow: "hidden",
    "&:hover $cardOverlay": {
      opacity: 1,
    },
  },
  cardOverlay: {
    padding: "0px 1rem",
    transition: "all 250ms ease",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    position: "absolute",
    borderRadius: 8,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0,
    color: palette.primary.contrastText,
    background: "rgba(0,0,0,0.67)",
    zIndex: 5,
  },
}));

const Gallery1 = ({data}) => {
  const classes = useStyles();

  const {
    title,
    text,
    gallery
  } = data;

  return (
    <section className="section" id="gallery1">
      <div className="container">
        <div className="mb-8">
          <h1 className="font-normal text-44 mt-0">{title}</h1>
          <p className="mb-16">
            {text}
          </p>
        </div>
        <Grid container spacing={3}>
          {gallery.map((item, ind) => (
            <Grid key={ind} item lg={4} md={4} sm={4} xs={12}>
              <div className={classes.cardHolder}>
                <img className="w-full block" src={item.image.url} alt={item.image.alt} />
                <div className={classes.cardOverlay}>
                  <h3 className="m-0">{item.title}</h3>
                  <p className="text-inherit">{item.category}</p>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </section>
  );
};

export default Gallery1;
