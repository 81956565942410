import React from "react";
import { Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";

const Service7 = ({data}) => {

  const {
    title,
    text,
    image,
    button_text,
    button_link
  } = data;

  return (
    <div className="section section-service7 bg-light-gray" id="service7">
      <div className="container">
        <Grid container alignItems="center" spacing={5}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="max-w-372 text-left">
              <h2 className="mb-4">
                {title}
              </h2>
              <p className="whitespace-pre-wrap">
                {text}
              </p>

              <Button variant="contained" color="secondary" className="mt-4">
                <Link to={button_link}>
                  {button_text}
                </Link>
              </Button>
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <img
              className="max-h-280"
              src={image.url}
              alt={image.alt}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Service7;
