import React from "react";

function TeamHomeOne({ data }) {
  const { sectionTitle, sectionDescription, teamMembers, viewAllMembersText } = data;

  return (
    <>
      <section className={`appie-team-area pt-90 pb-100`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">{sectionTitle}</h3>
                <p>{sectionDescription}</p>
              </div>
            </div>
          </div>
          <div className="row">
            {teamMembers.map((member, index) => (
              <div className="col-lg-3 col-md-6" key={index}>
                <div
                  className="appie-team-item mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay={`${200 * (index + 1)}ms`}
                >
                  <div className="thumb">
                    <img src={member.image.url} alt={member.name} />
                    <ul>
                      {member.socialLinks && member.socialLinks.map((link, i) => (
                        <li key={i}>
                          <a href={link.url}>
                            <i className={`fab ${link.icon}`} />
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="content text-center">
                    <h5 className="title">{member.name}</h5>
                    <span>{member.position}</span>
                  </div>
                </div>
              </div>
            ))}
            <div className="col-lg-12">
              <div className="team-btn text-center mt-50">
                <a className="main-btn" href="#">
                  {viewAllMembersText} <i className="fal fa-arrow-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TeamHomeOne;
