import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ValidateToken from "./validateToken";

/**
 * Create button and modal to edit each component on frontend
 */
const EditAcfComponents = ({postId , backendUrl, componentName}: any) => {

    const [show, setShow] = useState<boolean>(false);

    const handleClose = () => {
        setShow(false);
        window.location.reload();
    }
    const handleShow = () => setShow(true);

    if(!ValidateToken()){
      return <></>;
    }

    return(
        <div className="position-absolute end-0 mr-2 mt-2" style={{zIndex: 999999}}>
            <button className="btn btn-primary d-flex" onClick={handleShow}>
                <div className="material-icons material-symbols-outlined d-flex">
                    settings
                </div>
                <div className="d-flex">Edit</div>
            </button>

      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        // fullscreen={true}
        size="xl"
        className="left"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <iframe
                id="inlineFrameExample"
                title="Inline Frame Example"
                src={`${backendUrl}?p=${postId}&component=${componentName}`}
                height="100%" width="100%"
                >
            </iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
        </div>
    );
}

export default EditAcfComponents;