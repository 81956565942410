import axios from 'axios';
import React, { useState } from 'react';

/**
 * Simple Registe page
 */
const RegisterPage = () => {

    const [notification, setNotification] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [nicename, setNicename] = useState<string>('');

    const submitRegister = (e: any) => {
        e.preventDefault();
        axios.post(process.env.REACT_APP_API+`/wp/v2/users/register`, {
            username: username,
            email: nicename,
            password: password
         })
         .then( function( response ) {
            setNotification('Welcome');
            window.location.reload()
            document.location.href = '/login';
          } )
    
          .catch( function( error ) {
            setNotification(error.response.data.message);
            localStorage.setItem("token", "");
            // console.error( 'Error', error.data[0] );
          } ); 
    }

    return(
        <div className="d-flex justify-content-center bg-light pt-5 mt-5">
            <div className="border p-3 m-3 w-25 bg-white">
                <h2 className="text-primary">Register:</h2>
                    <div className="form-group text-secondary my-2">
                        <label htmlFor="exampleInputEmail1">Email address</label>
                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" onChange={(e : any) => setUsername(e.target.value)}/>
                    </div>
                    <div className="form-group text-secondary my-2">
                        <label htmlFor="exampleInputEmail1">Nicename</label>
                        <input type="text" className="form-control" id="exampleInputEmail2" aria-describedby="text" placeholder="Enter nicename" onChange={(e : any) => setNicename(e.target.value)}/>
                    </div>
                    <div className="form-group text-secondary my-2">
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" onChange={(e : any) => setPassword(e.target.value)}/>
                    </div>
                    <button type="submit" className="btn btn-primary" onClick={submitRegister}>Submit</button>
                    <div className="text-danger mt-2">
                        {notification}
                    </div>
            </div>
        </div>    
    )
}

export default RegisterPage;