import React, { useEffect, useState } from "react";
import EditAcfComponents from "./editAcfComponents";
import useAxios from "axios-hooks";
import HomeOneHeader from "../parts/Appie/HomeOne/HomeOneHeader";
import HeroHomeOne from "../parts/Appie/HomeOne/HeroHomeOne";
import ServicesHomeOne from "../parts/Appie/HomeOne/ServicesHomeOne";
import FeaturesHomeOne from "../parts/Appie/HomeOne/FeaturesHomeOne";
import TrafficHomeOne from "../parts/Appie/HomeOne/TrafficHomeOne";
import TestimonialHomeOne from "../parts/Appie/HomeOne/TestimonialHomeOne";
import TeamHomeOne from "../parts/Appie/HomeOne/TeamHomeOne";
import PricingHomeOne from "../parts/Appie/HomeOne/PricingHomeOne";
import FaqHomeOne from "../parts/Appie/HomeOne/FaqHomeOne";
import FooterHomeOne from "../parts/Appie/HomeOne/FooterHomeOne";
import ProjectHomeOne from "../parts/Appie/HomeOne/ProjectHomeOne";
import BlogHomeOne from "../parts/Appie/HomeOne/BlogHomeOne";
import BackToTop from "../parts/Appie/BackToTop";
import HeroAbout from "../parts/Appie/AboutUs/HeroAbout";
import HeaderAbout from "../parts/Appie/AboutUs/HeaderAbout";
import ServicesAbout from "../parts/Appie/AboutUs/ServicesAbout";
import TeamAbout from "../parts/Appie/AboutUs/TeamAbout";
import SponserHomeTwo from "../parts/Appie/HomeTwo/SponserHomeTwo";
import SponserHomeEight from "../parts/Appie/HomeEight/SignupHomeEight";
import HeaderNews from "../parts/Appie/News/HeaderNews";
import HeroNews from "../parts/Appie/News/HeroNews";
import Forms from "../parts/Appie/Contact/Forms";
import Intro1 from "../parts/custom/Intro1";
import Intro2 from "../parts/custom/Intro2";
import Intro3 from "../parts/custom/Intro3";
import Services1 from "../parts/custom/Services1";
import Service3 from "../parts/custom/Services3";
import Service4 from "../parts/custom/Services4";
import Service6 from "../parts/custom/Services6";
import Service5 from "../parts/custom/Services5";
import Service7 from "../parts/custom/Services7";
import Portfolio1 from "../parts/custom/Portfolio1";
import Testimonial1 from "../parts/custom/Testimonial1";
import Testimonial5 from "../parts/custom/Testimonial5";
import CallToAction1 from "../parts/custom/CallToAction1";
import Pricing1 from "../parts/custom/Pricing1";
import About1 from "../parts/custom/About1";
import Gallery1 from "../parts/custom/Gallery1";
import WorkProcess1 from "../parts/custom/WorkProcess1";
import Blog1 from "../parts/custom/Blog1";
import Speakers1 from "../parts/custom/Speakers1";
import EventSchedule1 from "../parts/custom/EventSchedule1";
import EventDetails1 from "../parts/custom/EventDetails1";
import Subscription4 from "../parts/custom/Subscription4";
import Test from "../parts/custom/test";
import HeroProduct from "../parts/Appie/Shop/HeroProduct";
import SliderWithDescriptionProduct from "../parts/Appie/Shop/SliderWithDescriptionProduct";
import DescriptionProduct from "../parts/Appie/Shop/DescriptionProduct";
import RelatedProduct from "../parts/Appie/Shop/RelatedProduct";
import MainShop from "../parts/Appie/Shop/MainShop";

/**
 * Components switch from ACF flexible content
 */
const SwitchComponents = ({ parts, postId }: any) => {
  const [backendUrl, setBackendUrl] = useState<string>("");

  const [{ data: optionsData, loading: optionsLoading }] = useAxios(
    process.env.REACT_APP_API + "/options/all"
  );

  useEffect(() => {
    if (!optionsLoading) {
      setBackendUrl(optionsData.backend_url);
    }
  }, [optionsData, optionsLoading]);

  return (
    <>
      {parts.map((singlePart: any, i: number) => {
        const displayPart = () => {
          switch (singlePart.acf_fc_layout) {
            case "homeoneheader":
              return <HomeOneHeader data={singlePart} />;
            case "herohomeone":
              return <HeroHomeOne data={singlePart} />;
            case "serviceshomeone":
              return <ServicesHomeOne data={singlePart} />;
            case "featureshomeone":
              return <FeaturesHomeOne data={singlePart} />;
            case "traffichomeone":
              return <TrafficHomeOne data={singlePart} />;
            case "testimonialhomeone":
              return <TestimonialHomeOne data={singlePart} />;
            case "teamhomeone":
              return <TeamHomeOne data={singlePart} />;
            case "pricinghomeone":
              return <PricingHomeOne data={singlePart} />;
            case "faqhomeone":
              return <FaqHomeOne data={singlePart} />;
            case "bloghomeone":
              return <BlogHomeOne data={singlePart} />;
            case "projecthomeone":
              return <ProjectHomeOne data={singlePart} />;
            case "footerhomeone":
              return <FooterHomeOne data={singlePart} />;

            case "headerabout":
              return <HeaderAbout data={singlePart} />;
            case "heroabout":
              return <HeroAbout data={singlePart} />;
            case "serviceabout":
              return <ServicesAbout data={singlePart} />;
            case "teamabout":
              return <TeamAbout data={singlePart} />;
            case "sponserhometwo":
              return <SponserHomeTwo data={singlePart} />;
            case "signuphomeeight":
              return <SponserHomeEight data={singlePart} />;
            case "headernews":
              return <HeaderNews data="" />;
            case "heronews":
              return (
                <HeroNews
                  title="Contact"
                  breadcrumb={[
                    { link: "/", title: "Home" },
                    { link: "/contact", title: "Contact" },
                  ]}
                />
              );
            case "forms":
              return <Forms />;
            case "intro":
              return <Intro1 data={singlePart} />;
            case "intro2":
              return <Intro2 data={singlePart} />;
            case "intro3":
              return <Intro3 data={singlePart} />;
            case "services":
              return <Services1 data={singlePart} />;
            case "service3":
              return <Service3 data={singlePart} />;
            case "service4":
              return <Service4 data={singlePart} />;
            case "service5":
              return <Service5 data={singlePart} />;
            case "service6":
              return <Service6 data={singlePart} />;
            case "service7":
              return <Service7 data={singlePart} />;
            case "portfolio":
              return <Portfolio1 data={singlePart} />;
            case "testimonial":
              return <Testimonial1 data={singlePart} />;
            case "testimonial5":
              return <Testimonial5 data={singlePart} />;
            case "call_to_action":
              return <CallToAction1 bg={""} data={singlePart} />;
            case "pricing":
              return <Pricing1 data={singlePart} />;
            case "about1":
              return <About1 data={singlePart} />;
            case "gallery1":
              return <Gallery1 data={singlePart} />;
            case "workprocess1":
              return <WorkProcess1 data={singlePart} />;
            case "blog1":
              return <Blog1 fields={singlePart} />;
            case "speakers1":
              return <Speakers1 data={singlePart} />;
            case "eventschedule1":
              return <EventSchedule1 data={singlePart} />;
            case "eventdetails1":
              return <EventDetails1 data={singlePart} />;
            case "subscription4":
              return <Subscription4 data={singlePart} />;
            case "test":
              return <Test data={singlePart} />;
            case "hero_product":
              return <HeroProduct data={singlePart} />;
            case "slider_with_description_product":
              return <SliderWithDescriptionProduct data={singlePart} />;
            case "description_product":
              return <DescriptionProduct data={singlePart} />;
            case "related_product":
              return <RelatedProduct data={singlePart} />;
            case "main_shop":
              return <MainShop data={singlePart} />;
            default:
              <></>;
          }
        };
        return (
          <div key={i + 1}>
            <EditAcfComponents
              postId={postId}
              backendUrl={backendUrl}
              componentName={singlePart.acf_fc_layout}
            />
            {displayPart()}
          </div>
        );
      })}
      <BackToTop className={""} />
    </>
  );
};

export default SwitchComponents;
