import useAxios from 'axios-hooks';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Gallery } from "react-grid-gallery";
import ValidateToken from '../../helpers/validateToken';
import MetaHelmet from '../../helpers/metaTags';

const Header = styled.div`
    height: 400;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

/**
 * Single blog post page
 */
const SinglePost = () => {

    const params = useParams();
    const [post, setPost] = useState<any>({});
    const [postId, setPostId] = useState<any>({});

    const [{ data, loading }] = useAxios(
        process.env.REACT_APP_API+`/wp/v2/posts?slug=${params.id}&acf_format=standard`
    )
    useEffect(() => {
        if(!loading){
            setPost(data[0]);
            setPostId(data[0]?.id);
        }
    }, [data, loading]);

    const [{ data: categoryData }] = useAxios(
        process.env.REACT_APP_API+`/wp/v2/category-slug/${post?.categories}`
    )

    const token = ValidateToken();

    useEffect(() => {
        if (post?.acf?.for_logged_users && !token){
            document.location.href = '/blog';
            return;
        }
    }, [post, token])

    return(
    <div className="mt-5 pt-5 container">
        <MetaHelmet postId={postId} postType="posts" />
        <Header
            className="p-5 text-center bg-image mt-5"
            style={{background: `url(${post?.acf?.thumbnail.sizes.large})`}}
        >
            <div className="mask">
            <div className="d-flex justify-content-center align-items-center h-100">
                <div className="text-white">
                <h1 className="mb-3">{post?.title?.rendered}</h1>
            </div>
            </div>
            </div>
        </Header>
        <div className="p-5">
            {categoryData && 
                <div className="categories alert alert-primary">
                    Category: <Link to={`${categoryData[1]}${categoryData[0].slug}`}>{categoryData[0].name}</Link>
                </div>
            }
            {post?.acf?.content}
            <div className="gallery pt-3">
                <Gallery images={post?.acf?.gallery} />
            </div>
        </div>
    </div>
    );

}

export default SinglePost;
