import React from "react";
import { SvgIcon } from "@mui/material";

const FacebookIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 10.041 19">
      <path
        d="M134.276,7.173V4.565a1.281,1.281,0,0,1,1.255-1.3h1.255V0h-2.51a3.841,3.841,0,0,0-3.765,3.913V7.173H128v3.261h2.51V20.868h3.765V10.434h2.51l1.255-3.261Z"
        transform="translate(-128)"
      />
    </SvgIcon>
  );
};

export default FacebookIcon;
