import React, { useState } from "react";

function FaqHomeOne({ data }) {
  const [showQues, setQues] = useState(1);
  const openQuestion = (value) => {
    setQues(value);
  };

  const faqItems = data.faqs.map((faq, index) => (
    <div
      key={index}
      onClick={() => openQuestion(index + 1)}
      className={`accrodion ${showQues === index + 1 ? "active" : ""}`}
    >
      <div className="accrodion-inner">
        <div className="accrodion-title">
          <h4>{faq.question}</h4>
        </div>
        <div
          className="accrodion-content"
          style={{ display: showQues === index + 1 ? "block" : "none" }}
        >
          <div className="inner">
            <p>{faq.answer}</p>
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <section className={`appie-faq-area pb-95`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">{data.title}</h3>
                <p>{data.subtitle}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div
                className="faq-accordion wow fadeInRight mt-30"
                data-wow-duration="1500ms"
              >
                <div
                  className="accrodion-grp animated fadeIn faq-accrodion wow"
                  data-wow-duration="1500ms"
                  data-grp-name="faq-accrodion"
                >
                  {faqItems.slice(0, 2)}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="faq-accordion wow fadeInRight mt-30"
                data-wow-duration="1500ms"
              >
                <div
                  className="accrodion-grp animated fadeIn faq-accrodion wow"
                  data-wow-duration="1500ms"
                  data-grp-name="faq-accrodion"
                >
                  {faqItems.slice(2)}
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="faq-text text-center pt-40">
                <p>
                  Can't find an answer?{" "}
                  <a href={`mailto:${data.email}`}>Email us</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FaqHomeOne;