import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";
import MetaHelmet from "../../helpers/metaTags";
import { useSearchParams } from "react-router-dom";
import SwitchComponents from "../../helpers/switchComponents";

/**
 * Create Home Page from `frontpage` endpoint
 */
const HomePage = () => {
  const [parts, setParts] = useState<any[]>([]);
  const [postId, setPostId] = useState<string>("");

  const [searchParams] = useSearchParams();
  const isPreview = searchParams.get("preview");
  const [{ data, loading }] = useAxios(
    isPreview
      ? process.env.REACT_APP_API + `/wp/v2/preview-homepage`
      : process.env.REACT_APP_API + "/wp/v2/frontpage"
  );

  useEffect(() => {
    if (!loading) {
      setParts(data.acf.appie);
      setPostId(data.ID);
    }
  }, [data, loading]);

  return (
    <>
      <MetaHelmet postId={postId} />
      <SwitchComponents parts={parts} postId={postId} />
    </>
  );
};

export default HomePage;
