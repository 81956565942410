import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import product from "../../../assets/images/shop-details-thumb-1.jpg";

function RelatedProduct({ data }) {
  const { title, subtitle, slider } = data;

  const rlProductSettings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="shop-related-area pt-90 pb-170">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">{title}</h3>
                <p>{subtitle}</p>
              </div>
            </div>
          </div>
          <div className="shop-related-product-slider-active">
            <Slider {...rlProductSettings} dots>
              {slider.map((slide, index) => (
                <div className="px-3">
                  <div className="single-shop-box">
                    <div className="thumb text-center">
                      <img src={slide.image.url} alt="" />
                      <div className="reborn">
                        {slide.label && <span>{slide.label}</span>}
                      </div>
                      <div className="cart-list-icon">
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fal fa-shopping-bag"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fal fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fal fa-eye"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="content">
                      <ul>
                        <li>
                          <i className="fas fa-star"></i>
                        </li>
                        <li>
                          <i className="fas fa-star"></i>
                        </li>
                        <li>
                          <i className="fas fa-star"></i>
                        </li>
                        <li>
                          <i className="fas fa-star"></i>
                        </li>
                        <li>
                          <i className="fas fa-star-half-alt"></i>
                        </li>
                      </ul>
                      <a href="#">{slide.title}</a>
                      <div className="pricing">
                        <div className="discount-price">{slide.price}</div>
                        <div className="regular-price">{slide.promotion}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}

export default RelatedProduct;
