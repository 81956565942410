import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";
import MetaHelmet from "../../helpers/metaTags";
import BlogSinglePostPart from "./blogSinglePostPart";
import HomeOneHeader from "../../parts/Appie/HomeOne/HomeOneHeader";
import FooterHomeOne from "../../parts/Appie/HomeOne/FooterHomeOne";

/**
 * Display all blog posts
 */
const Blog = () => {
  const [posts, setPosts] = useState<any[]>([]);
  const [blogPageId, setBlogPageId] = useState<string>("");

  const [{ data, loading }] = useAxios(
    process.env.REACT_APP_API + "/wp/v2/posts?acf_format=standard"
  );

  const [{ data: blogData }] = useAxios(
    process.env.REACT_APP_API + "/wp/v2/blogpage"
  );

  useEffect(() => {
    if (!loading) {
      setPosts(data);
      setBlogPageId(blogData?.ID);
    }
  }, [data, loading, blogData]);

  return (
    <>
      <MetaHelmet postId={blogPageId} />
      <HomeOneHeader data="" />
      <div className="row mt-14 mb-5 pt-5 container">
        {posts.map((singlePost) => {
          return <BlogSinglePostPart singlePost={singlePost} />;
        })}
      </div>
      <FooterHomeOne data="" />
    </>
  );
};

export default Blog;
