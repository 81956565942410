import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';
import clsx from "clsx";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  card: {
    "& .card-icon": {
      fontSize: 64,
      transition: "all 250ms ease-in-out",
    },
    "&:hover": {
      "& .card-icon": {
        color: palette.primary.main,
      },
    },
  },
}));

const Service6 = ({data}) => {
  const classes = useStyles();

  const {
    title,
    subtitle,
    points
  } = data;

  return (
    <div className="section" id="service6">
      <div className="container">
        <div className="section__header mb-48">
          <h2>{title}</h2>
          <p>{subtitle}</p>
        </div>
        <Grid container justify="center" alignItems="center" spacing={4}>
          {points.map((card, index) => (
            <Grid
              item
              lg={3}
              md={3}
              sm={12}
              xs={12}
              key={index}
              className="text-center"
            >
              <div
                className={clsx(
                  "h-112 w-112 rounded overflow-hidden mx-auto p-6 card",
                  classes.card
                )}
              >
                <img style={{maxWidth: 75}} src={card.image.url} alt={card.image.alt} />
              </div>
              <p className="uppercase">{card.title}</p>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default Service6;
