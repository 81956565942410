import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import parse from 'html-react-parser';
import useAxios from "axios-hooks";

/**
 * Display metadescription for any page or post works with Yoast plugin
 */
const MetaHelmet = ({postId, postType = 'pages'}: any) => {

    const [metaDescription, setMetaDescription] = useState<string>('');
    const [cookieBot, setCookieBot] = useState<string>('');

    const [{ data, loading }] = useAxios(
        process.env.REACT_APP_API+`/wp/v2/${postType}/${postId}`
    );
    const [{ data: optionsData }] = useAxios(
        process.env.REACT_APP_API+'/options/all'
    );

    useEffect(() => {
        if(!loading){
            setMetaDescription(data?.yoast_head);
            setCookieBot((optionsData?.cookiebot));
        }
    }, [data, loading, optionsData])

    return(
        <Helmet>
            {metaDescription && parse(metaDescription)}
            {cookieBot && parse(cookieBot)}
        </Helmet>
    )
}

export default MetaHelmet;
