import React from "react";

import { Grid, Button, IconButton, LinearProgress } from "@mui/material";
import { withStyles } from "@mui/styles";

import clsx from "clsx";
import { Link } from "react-router-dom";

const RoundedLinearProgressbar = withStyles(({ palette }) => ({
  root: {
    borderRadius: 300,
    height: 6,
    background: "transparent",
  },
  bar: {
    borderRadius: 300,
  },
}))(LinearProgress);

const About1 = ({ data }) => {
  const { title, text, subtitle, button_text, button_link, bars } = data;

  return (
    <section className="section" id="about1">
      <div className="container">
        <Grid container spacing={6} alignItems="center">
          <Grid item sm={6} xs={12}>
            <div>
              <h1 className="font-normal text-44 mt-0">{title}</h1>
              <p className="max-w-400 my-6 text-primary text-18 font-meidum">
                {subtitle}
              </p>
              <p className="max-w-400 mb-15">{text}</p>

              <Button
                className="rounded px-10"
                variant="contained"
                color="primary"
              >
                <Link to={button_link}>{button_text}</Link>
              </Button>
            </div>
          </Grid>
          <Grid item sm={6} xs={12}>
            {bars.map((item, ind) => (
              <div
                key={ind}
                className={clsx({
                  "flex items-center flex-wrap": true,
                  "mb-6": ind !== bars.length - 1,
                })}
              >
                <IconButton className="bg-light-primary mr-4 p-11px">
                  <img
                    style={{ width: 25 }}
                    src={item.icon.url}
                    alt={item.icon.alt}
                  />
                </IconButton>
                <div className="flex-grow">
                  <p className="mt-0 mb-2">{item.title}</p>
                  <RoundedLinearProgressbar
                    value={parseInt(item.percentage)}
                    variant="determinate"
                  />
                </div>
              </div>
            ))}
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default About1;
