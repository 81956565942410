import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

/**
 * Single blog card on index page
 */
const BlogSinglePostPart = ({ singlePost }: any) => {
  const [categoryUrl, setCategoryUrl] = useState<any>("");

  const [{ data: categoryData, loading }] = useAxios(
    process.env.REACT_APP_API + `/wp/v2/category-slug/${singlePost.categories}`
  );
  useEffect(() => {
    if (categoryData) {
      setCategoryUrl(categoryData[1] + categoryData[0].slug);
    }
  }, [categoryData, loading]);

  return (
    <>
      <div
        className="col-md-6 col-lg-4 mt-5 wow fadeInUp"
        data-wow-delay=".2s"
        key={singlePost.id}
      >
        <div className="blog-grid">
          <div className="blog-grid-img position-relative">
            <img alt="img" src={singlePost.acf.thumbnail.sizes.medium} />
          </div>
          <div className="blog-grid-text p-4">
            <h3 className="h5 mb-3">
              <Link
                to={
                  singlePost.acf.for_logged_users
                    ? categoryUrl + "/" + singlePost.slug
                    : categoryUrl + "/" + singlePost.slug
                }
              >
                {singlePost.title.rendered}
              </Link>{" "}
              {singlePost.acf.for_logged_users && "- Only for logged in users"}
            </h3>
            <p className="display-30">{singlePost.acf.short_content}</p>
            <div className="meta meta-style2">
              <ul>
                <li>
                  <i className="fas fa-calendar-alt"></i>
                  {singlePost.date}
                </li>
                <li>
                  <a href="#!">
                    <i className="fas fa-user"></i> User
                  </a>
                </li>
              </ul>
              {categoryData && (
                <div className="categories alert alert-primary mt-2">
                  Category: <Link to={categoryUrl}>{categoryData[0].name}</Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogSinglePostPart;
