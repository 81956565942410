import React, { useState } from "react";
import { Link } from "react-router-dom";

function FeaturesHomeOne({ data }) {
  const [tab, setTab] = useState("setting");
  const handleClick = (e, value) => {
    e.preventDefault();
    setTab(value);
  };


  const features = [
    {
      id: "setting",
      icon: "fas fa-cog",
      title: data.setting_title,
      content: data.setting_content_description,
      link: data.setting_link,
    },
    {
      id: "report",
      icon: "fas fa-exclamation-triangle",
      title: data.report_title,
      content: data.report_content_description,
      link: data.report_link,
    },
    {
      id: "notice",
      icon: "fas fa-bell",
      title: data.notice_title,
      content: data.notice_content_description,
      link: data.notice_link,
    },
    {
      id: "app",
      icon: "fas fa-lock",
      title: data.app_title,
      content: data.app_content_description,
      link: data.app_link,
    },
  ];

  return (
    <section className={`appie-features-area pt-100`} id="features">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-3">
            <div className="appie-features-tabs-btn">
              <div
                className="nav flex-column nav-pills"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                {features.map((feature) => (
                  <a
                    key={feature.id}
                    onClick={(e) => handleClick(e, feature.id)}
                    className={`nav-link ${tab === feature.id ? "active" : ""}`}
                    id={`v-pills-${feature.id}-tab`}
                    data-toggle="pill"
                    href={`#v-pills-${feature.id}`}
                    role="tab"
                    aria-controls={`v-pills-${feature.id}`}
                    aria-selected={tab === feature.id}
                  >
                    <i className={feature.icon} />{" "}
                    {feature.id.charAt(0).toUpperCase() + feature.id.slice(1)}
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="tab-content" id="v-pills-tabContent">
              {features.map((feature) => (
                <div
                  key={feature.id}
                  className={`${
                    tab === feature.id ? "show active" : ""
                  } tab-pane fade`}
                  id={`v-pills-${feature.id}`}
                  role="tabpanel"
                  aria-labelledby={`v-pills-${feature.id}-tab`}
                >
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div
                        className="appie-features-thumb text-center wow animated fadeInUp"
                        data-wow-duration="2000ms"
                        data-wow-delay="200ms"
                      >
                        <img src={data.image?.url} alt="" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="appie-features-content wow animated fadeInRight"
                        data-wow-duration="2000ms"
                        data-wow-delay="600ms"
                      >
                        <span>{feature.title}</span>
                        <h3 className="title">{feature.title}</h3>
                        <p>{feature.content}</p>
                        <Link className="main-btn" to={feature.link}>
                          Learn More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="features-shape-1">
        <img src={data?.shape_six?.url} alt="" />
      </div>
      <div className="features-shape-2">
        <img src={data?.shape_seven?.url} alt="" />
      </div>
      <div className="features-shape-3">
        <img src={data?.shape_eight?.url} alt="" />
      </div>
    </section>
  );
}

export default FeaturesHomeOne;
