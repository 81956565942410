import React, { useEffect } from "react";
import logo from "../../../assets/images/logo.png";
import StickyMenu from "../../../lib/StickyMenu.js";
import Navigation from "../Navigation.jsx";
import { Link } from "react-router-dom";

function HomeOneHeader({ darkEnable = false, langEnabled = false, data }) {
  useEffect(() => {
    StickyMenu();
  });

  const { logoImage, loginText, getStartedText } = data;

  return (
    <header className={`appie-header-area appie-sticky`}>
      <div className="container">
        <div className="header-nav-box">
          <div className="row align-items-center">
            <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
              <div className="appie-logo-box">
                <Link to="/">
                  <img src={logoImage?.url || logo} alt="" />
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2">
              <div className="appie-header-main-menu">
                <Navigation />
              </div>
            </div>
            <div className="col-lg-4 col-md-7 col-sm-6 col-6 order-2 order-sm-3">
              <div className="appie-btn-box text-right">
                {darkEnable && (
                  <span className="dark__btn__sun">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                      ></path>
                    </svg>
                  </span>
                )}
                <a className="login-btn" href="#">
                  <i className="fal fa-user" /> {loginText}
                </a>
                <a className="main-btn ml-30" href="#">
                  {getStartedText}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HomeOneHeader;
