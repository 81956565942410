import React from "react";
import "./App.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/custom-animated.css";
import "./assets/css/default.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/magnific.dark.css";
import "./assets/css/magnific.rtl.css";
import "./assets/css/main.css";
import "./assets/css/style.css";
import { Route, Routes } from "react-router";
import HomePage from "./components/HomePage";
import Blog from "./components/Blog";
import SinglePost from "./components/Blog/singlePost";
import LoginPage from "./components/LoginPage";
import RegisterPage from "./components/RegisterPage";
import Page from "./components/Page";
import GlobalCss from "./styles/jss/GlobalCss";
import { Theme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import Category from "./components/Blog/category";

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <GlobalCss>
        {/* <TopBar1 /> */}
        <div className="">
          <Routes>
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:category/" element={<Category />} />
            <Route path="/blog/:category/:id" element={<SinglePost />} />
            <Route path="/:id" element={<Page />} />
          </Routes>
        </div>
        {/* <Footer1 /> */}
      </GlobalCss>
    </ThemeProvider>
  );
}

export default App;
