import React from "react";

import { Button, Icon, Card, CardContent, Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';

import clsx from "clsx";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  card: {
    borderTop: "2px solid black",
    "& .icon": {
      fontSize: 64,
    },

    "&:hover": {
      borderTop: "2px solid rgba(var(--primary), 1)",
      "& .icon": {
        color: "rgba(var(--primary),1)",
      },
    },
  },
}));

const Services1 = ({data}) => {
  const classes = useStyles();

  const {
    list,
    title,
    subtitle
  } = data;

  return (
    <section className="section bg-light-gray" id="service1">
      <div className="container">
        <div className="section__header">
          <h2>{title}</h2>
          <p>
            {subtitle}
          </p>
        </div>

        <Grid container spacing={3} alignContent="stretch">
          {list.map((service) => (
            <Grid item md={3} sm={6} key={service.title}>
              <Card className={clsx("card h-full", classes.card)}>
                <CardContent className="flex-column justify-between min-h-full">
                  <div className="flex-grow">
                    <div className="text-center mb-4">
                      <Icon className="icon">{service.icon}</Icon>
                    </div>
                    <h3 className="font-light text-24">{service.title}</h3>
                    <p>{service.text}</p>
                  </div>
                  <div className="pt-4">
                    <Button>READ MORE</Button>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </section>
  );
};

export default Services1;
