import React from "react";
import { makeStyles } from '@mui/styles';
import clsx from "clsx";
import { IconButton, Icon } from "@mui/material";
import { Link } from "react-router-dom";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  section: {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.67), rgba(0,0,0,0.67)), 
       url('./assets/images/event-2.jpg')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  buttonBorder: {
    border: "1px solid white",
  },
}));

const EventDetails1 = ({data}) => {

  const {
    title,
    text,
    link
  } = data;

  const classes = useStyles();

  return (
    <section className={clsx("section", classes.section)} id="event-details">
      <div className="container">
        <div className="text-center text-white">
          <h1 className="m-0 text-48 font-normal inline-block mx-auto">
            {title}
          </h1>
          <p className="my-8 max-w-600 mx-auto text-inherit">
            {text}
          </p>
          <Link to={link}>
            <IconButton className={classes.buttonBorder}>
              <Icon className="text-white">play_arrow</Icon>
            </IconButton>
          </Link>  
        </div>
      </div>
    </section>
  );
};

export default EventDetails1;
