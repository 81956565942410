import React from "react";

const Test = ({ data }: any) => {
  const { test } = data;

  return (
    <div className="section" id="testimonial1">
      Tekst from test component: {test}
    </div>
  );
};

export default Test;
