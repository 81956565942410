import React from "react";
import { Grid, Card, Icon, Button } from "@mui/material";
import { makeStyles } from '@mui/styles';
import clsx from "clsx";
import { Link } from "react-router-dom";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  borderedIcon: {
    border: "2px solid rgba(var(--secondary),0.8)",
  },
}));

const Service3 = ({data}) => {
  const classes = useStyles();

  const {
    title,
    text,
    points,
    button_text,
    button_link
  } = data;

  return (
    <div className="section section-service3 bg-light-gray" id="service3">
      <div className="container">
        <Grid container spacing={5} alignItems="center">
          <Grid item lg={6} md={6} sm={12}>
            <Grid container spacing={4}>
              {points.map((card, index) => (
                <Grid item lg={6} md={6} sm={6} xs={12} key={index}>
                  <Card className="service__card p-6 card text-center h-full">
                    <div
                      className={clsx(
                        "flex justify-center items-center rounded w-56 h-56 text-secondary bg-light-secondary mx-auto mb-4",
                        classes.borderedIcon
                      )}
                    >
                      <Icon>{card.icon}</Icon>
                    </div>
                    <p className="m-0 uppercase">{card.title} </p>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item lg={6} md={6} sm={12}>
            <h2 className="">{title}</h2>
            <p>
              {text}
            </p>
            <Button variant="contained" color="secondary">
              <Link to={button_link}>
                {button_text}
              </Link>
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Service3;
