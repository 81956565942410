import React from "react";

function BlogHomeOne({ data }) {

  const blogItems = data.blog_items.map((item, index) => (
    <div className="col-lg-4 col-md-6" key={index}>
      <div
        className="appie-blog-item mt-30 wow animated fadeInUp"
        data-wow-duration="3000ms"
        data-wow-delay={`${200 + index * 200}ms`}
      >
        <div className="thumb">
          <img src={item.image.url} alt="" />
        </div>
        <div className="content">
          <div className="blog-meta">
            <ul>
              <li>{item.date}</li>
              <li>
                <a href="#">{item.category}</a>
              </li>
            </ul>
          </div>
          <h3 className="title">
            <a href={`/news/${item.slug}`}>{item.title}</a>
          </h3>
          <a href="#">
            Learn More <i className="fal fa-arrow-right" />
          </a>
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <section className={`appie-blog-area pt-90 pb-95`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">Latest blog posts</h3>
                <p>Different layouts and styles for team sections.</p>
              </div>
            </div>
          </div>
          <div className="row">{blogItems}</div>
        </div>
      </section>
    </>
  );
}

export default BlogHomeOne;
