import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

function SliderWithDescriptionProduct({ data }) {
  const [quantity, setQuantity] = useState(1);
  const sliderRef = useRef();
  const quantityHandler = (e) => {
    setQuantity(e.target.value);
  };

  const {
    button_text,
    description,
    link,
    price,
    promotion,
    rating,
    rating_text,
    sku,
    slider,
    title,
  } = data;

  return (
    <>
      <section className="shop-details-area pt-100 pb-100">
        <div className="container">
          <div className="row ">
            <div className="col-lg-6">
              <div className="shop-details-thumb">
                <div className="shop-details-thumb-slider-active">
                  <Slider ref={sliderRef} autoplay="true">
                    {slider.map((slide, index) => (
                      <div className="item">
                        <img src={slide.image.url} alt="" />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="shop-product-details-content pl-70 mt-35">
                <span></span>
                <h2 className="title">{title}</h2>
                <div className="pricing">
                  <div className="discount-price mr-15">{price}</div>
                  {promotion && (
                    <div className="regular-price">{promotion}</div>
                  )}
                </div>
                <div className="review">
                  <ul>
                    <li>
                      <i className="fas fa-star"></i>
                    </li>
                    <li>
                      <i className="fas fa-star"></i>
                    </li>
                    <li>
                      <i className="fas fa-star"></i>
                    </li>
                    <li>
                      <i className="fas fa-star"></i>
                    </li>
                    <li>
                      <i className="fas fa-star-half-alt"></i>
                    </li>
                  </ul>
                  <span>({rating_text})</span>
                </div>
                <p>{description}</p>
                <div className="shop-buttons d-block d-sm-flex align-items-center">
                  <div className="product-quantity" id="quantity">
                    <button
                      onClick={() => quantity > 1 && setQuantity(quantity - 1)}
                      type="button"
                      id="sub"
                      className="sub"
                    >
                      -
                    </button>
                    <input
                      onChange={(e) => quantityHandler(e)}
                      type="text"
                      id="1"
                      value={quantity}
                    />
                    <button
                      onClick={() => setQuantity(quantity + 1)}
                      type="button"
                      id="add"
                      className="add"
                    >
                      +
                    </button>
                  </div>
                  <a className="main-btn ml-10" href={link}>
                    {button_text}
                  </a>
                </div>
                <div className="details-info">
                  <ul>
                    <li>
                      <span>SKU:</span> {sku}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SliderWithDescriptionProduct;
