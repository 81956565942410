import React from "react";

function ServicesHomeOne({ data }) {
  const services = data.services.map((service, index) => ({
    ...service,
    id: index + 1,
  }));

  return (
    <section className={`appie-service-area pt-90 pb-100 `} id="service">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="appie-section-title text-center">
              <h3 className="appie-title">{data.title}</h3>
              <p>{data.subtitle}</p>
            </div>
          </div>
        </div>
        <div className="row">
          {services.map(({ title, service_text, icon }, index) => (
            <div className="col-lg-3 col-md-6" key={index}>
              <div
                className={`appie-single-service text-center mt-30 wow animated fadeInUp`}
                data-wow-duration="2000ms"
                data-wow-delay={`${(index + 1) * 200}ms`}
              >
                <div className="icon">
                  <img src={icon} alt={title} />
                  <span>{index + 1}</span>
                </div>
                <h4 className="appie-title">{title}</h4>
                <p>{service_text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ServicesHomeOne;
