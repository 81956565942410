import React from "react";

import { Grid, Card, Button } from "@mui/material";

import { makeStyles } from '@mui/styles';
import clsx from "clsx";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  card: {
    borderRadius: 8,
    "&:hover $cardHeader": {
      background: palette.primary.main,
      color: "white",
    },
    "&:hover $largeText": {
      color: "rgba(var(--primary),0.3)",
    },
  },
  cardHeader: {
    padding: "1.25rem 2rem",
    fontSize: "1rem",
    fontWeight: 500,
    background: "rgba(var(--primary),0.3)",
    transition: "all 250ms ease",
  },
  cardContent: {
    padding: "1.5rem 2rem",
  },
  largeText: {
    textAlign: "right",
    fontSize: "8rem",
    fontWeight: 400,
    lineHeight: 1,
    marginTop: "1.5rem",
    color: "rgba(var(--primary),0.1)",
    transition: "all 250ms ease",
  },
}));

const WorkProcess1 = ({data}) => {
  const classes = useStyles();

  const {
    title,
    text,
    process
  } = data;

  return (
    <section className="section" id="work-process1">
      <div className="container">
        <div>
          <h1 className="font-normal text-44 mt-0">{title}</h1>
          <p className="mb-16">
            {text}
          </p>
        </div>

        <Grid container spacing={3}>
          {process.map((item, ind) => (
            <Grid key={ind} item sm={4} xs={12}>
              <Card elevation={3} className={clsx(classes.card, "card")}>
                <div className={classes.cardHeader}>{item.title}</div>
                <div className={classes.cardContent}>
                  <p className="mb-8 mt-0">
                    {item.text}
                  </p>
                  <Button variant="text" color="primary">
                    CLICK TO VIEW MORE
                  </Button>
                  <div className={classes.largeText}>{ind + 1}</div>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </section>
  );
};

export default WorkProcess1;
