  
import React, { useState } from "react";
import {
  Switch,
  FormControlLabel,
  Grid,
  Card,
  CardContent,
  Button,
  Divider,
} from "@mui/material";
import { makeStyles } from '@mui/styles';

import clsx from "clsx";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  card: {
    "& .card-header": {
      background: "rgba(0, 0, 0, 0.024)",
    },
    "& .card-header-highlighted": {
      background: "rgba(var(--primary),1)",
      "& span": {
        color: "#fff",
      },
    },
  },
}));

const Pricing1 = ({data}) => {
  const [state, setState] = useState({
    switchToggled: false,
    plan: "Mo",
    off: 10,
  });

  const {
    title,
    subtitle,
    options
  } = data;

  const classes = useStyles();

  const handleSwitchChange = () => {
    let { switchToggled, plan } = state;
    switchToggled = !switchToggled;
    switchToggled ? (plan = "Yr") : (plan = "Mo");
    setState({ ...state, switchToggled, plan });
  };

  return (
    <div className="section section-pricing1" id="pricing1">
      <div className="container">
        <div className="mb-6">
          <h2>{title}</h2>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={state.switchState}
                onChange={handleSwitchChange}
              />
            }
            label={subtitle}
          />
        </div>

        <Grid container spacing={2}>
          {options.map((plan) => {
            let { isdefault, title, subtitle, price, list } = plan;

            return (
              <Grid item lg={3} md={3} sm={6} xs={12} key={title}>
                <Card className={clsx("text-center card", classes.card)}>
                  <div
                    className={clsx({
                      "card-header flex-column items-center p-3": true,
                      "card-header-highlighted": isdefault,
                    })}
                  >
                    <span className="text-16">{title}</span>
                    <span className="text-small">{subtitle}</span>
                  </div>
                  <Divider className="mb-2" />
                  <CardContent className="pricing1__card-content">
                    <h1 className="mt-0 mb-4 text-32">
                      {price}
                    </h1>

                    {list.map((offer, index) => (
                      <div
                        key={index}
                        className={clsx({
                          "px-3 py-2": true,
                          "text-muted": offer.muted,
                        })}
                      >
                        {offer.single}
                      </div>
                    ))}

                    <div className="text-center mt-4">
                      <Button color="secondary" variant="contained">
                        Choose
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

export default Pricing1;
