import React, { useState } from "react";

function PricingHomeOne({ data }) {
  const [toggleSwitch, setSwitchValue] = useState(true);
  
  const plans = [
    {
      name: "Fresh",
      monthlyPrice: 4,
      yearlyPrice: 32,
      features: [
        "60-day chat history",
        "15 GB cloud storage",
      ],
      trialText: "Get your 14 day free trial",
    },
    {
      name: "Sweet",
      monthlyPrice: 16,
      yearlyPrice: 116,
      features: [
        "60-day chat history",
        "50 GB cloud storage",
        "24/7 Support",
      ],
      trialText: "Billed $276 per website annually.",
      popular: true,
    },
    {
      name: "Juicy",
      monthlyPrice: 27,
      yearlyPrice: 227,
      features: [
        "60-day chat history",
        "Data security",
        "100 GB cloud storage",
        "24/7 Support",
      ],
      trialText: "Billed $276 per website annually.",
    },
  ];

  return (
    <>
      <section className={`appie-pricing-area pt-90 pb-90 `}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">{data.title}</h3>
                <p>{data.subtitle}</p>
              </div>
              <ul
                className="list-inline text-center switch-toggler-list"
                role="tablist"
                id="switch-toggle-tab"
              >
                <li className={`month ${toggleSwitch ? "active" : ""}`}>
                  <a href="#">{data.monthly_label}</a>
                </li>
                <li>
                  <label
                    onClick={() => setSwitchValue(!toggleSwitch)}
                    className={`switch ${toggleSwitch ? "on" : "off"}`}
                  >
                    <span className="slider round" />
                  </label>
                </li>
                <li
                  className={`year ${!toggleSwitch ? "active" : ""}`}
                >
                  <a href="#">{data.annually_label}</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="tabed-content">
            {toggleSwitch ? (
              <div id="month">
                <div className="row justify-content-center">
                  {plans.map((plan) => (
                    <div className="col-lg-4 col-md-6" key={plan.name}>
                      <div className={`pricing-one__single ${plan.popular ? "center" : ""}`}>
                        <div className="pricig-heading">
                          <h6>{plan.name}</h6>
                          <div className="price-range">
                            <sup>$</sup> <span>{plan.monthlyPrice}</span>
                            <p>/month</p>
                          </div>
                          <p>{plan.trialText}</p>
                        </div>
                        <div className="pricig-body">
                          <ul>
                            {plan.features.map(feature => (
                              <li key={feature}>
                                <i className="fal fa-check" /> {feature}
                              </li>
                            ))}
                          </ul>
                          <div className="pricing-btn mt-35">
                            <a className="main-btn" href="#">
                              {data.choosePlan}
                            </a>
                          </div>
                        </div>
                        {plan.popular && (
                          <div className="pricing-rebon">
                            <span>{data.popularText}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div id="year">
                <div className="row justify-content-center">
                  {plans.map((plan) => (
                    <div className="col-lg-4 col-md-6" key={plan.name}>
                      <div className={`pricing-one__single ${plan.popular ? "center" : ""}`}>
                        <div className="pricig-heading">
                          <h6>{plan.name}</h6>
                          <div className="price-range">
                            <sup>$</sup> <span>{plan.yearlyPrice}</span>
                            <p>/Yearly</p>
                          </div>
                          <p>{plan.trialText}</p>
                        </div>
                        <div className="pricig-body">
                          <ul>
                            {plan.features.map(feature => (
                              <li key={feature}>
                                <i className="fal fa-check" /> {feature}
                              </li>
                            ))}
                          </ul>
                          <div className="pricing-btn mt-35">
                            <a className="main-btn" href="#">
                              {data.choosePlan}
                            </a>
                          </div>
                        </div>
                        {plan.popular && (
                          <div className="pricing-rebon">
                            <span>{data.popularText}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default PricingHomeOne;
