import React from "react";

import { Grid, Button } from "@mui/material";
import { makeStyles } from '@mui/styles';

import clsx from "clsx";
import { Link } from "react-router-dom";

const useStyles = makeStyles(({ palette, ...theme }) => ({

}));

const CallToAction1 = ({ bg, data }) => {
  const classes = useStyles({ bg });

  const {
    title,
    subtitle,
    button_text,
    button_link
  } = data;

  return (
    <div className={clsx("section text-white", classes.sectionBG)} id="cta1" style={{background: `url(${
      "./assets/images/home-bg.jpg"
    }) center center/cover no-repeat`}}>
      <div className="container">
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="center"
          justify="flex-start"
        >
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <h2>{title}</h2>
            <p className="text-inherit">
              {subtitle}
            </p>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} className="text-center">
            <Button size="large" color="secondary" variant="contained">
              <Link to={button_link}>{button_text}</Link>
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CallToAction1;
