import { useEffect, useState } from 'react';
import axios from 'axios';

/**
 * Token validation
 */
const ValidateToken = () => {
        const [validate, setValidate] = useState<boolean>(false);    

        useEffect(() => {
            axios.post(process.env.REACT_APP_API+`/jwt-auth/v1/token/validate`, {}, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem("token")}` 
            }})
         .then( function( response ) {
            setValidate(true);
          } )    
          .catch( function( error ) {
            setValidate(false);
          } );
        }, [validate])
        
        return validate;
}

export default ValidateToken;