// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*==== rtl css ====*/



.appie-traffic-area.appie-traffic-area-rtl .traffic-thumb {
	left: 0;
	right: auto;
	transform: translateY(-50%) rotateY(-180deg);
}


.appie-traffic-area-rtl .appie-traffic-title{
	padding-left: 220px;
	padding-right: 0;
}


.appie-traffic-area-rtl .appie-traffic-service .icon{
	right: 0;
	left: auto;
}



.appie-faq-area-rtl .faq-accrodion .accrodion .accrodion-title::after,
.appie-faq-area-rtl .faq-accrodion .accrodion .accrodion-title::before{
	right: auto;
	left: 30px;
}


.appie-project-area.appie-project-area-rtl .appie-project-box .appie-project-thumb{
	left: 70px;
	right: auto;
}



.appie-project-area-rtl .appie-project-content .input-box input{
	padding-left: 0;
	padding-right: 30px;
}


.appie-project-area-rtl .appie-project-content .input-box button{
	margin-left: 0;
	margin-right: 10px;
}





.appie-header-area-rtl .appie-header-main-menu ul{
	text-align: right;
}





.appie-header-area-rtl .appie-header-main-menu ul > li .sub-menu{
	text-align: right;
}




















`, "",{"version":3,"sources":["webpack://./src/assets/css/magnific.rtl.css"],"names":[],"mappings":"AAAA,oBAAoB;;;;AAIpB;CACC,OAAO;CACP,WAAW;CACX,4CAA4C;AAC7C;;;AAGA;CACC,mBAAmB;CACnB,gBAAgB;AACjB;;;AAGA;CACC,QAAQ;CACR,UAAU;AACX;;;;AAIA;;CAEC,WAAW;CACX,UAAU;AACX;;;AAGA;CACC,UAAU;CACV,WAAW;AACZ;;;;AAIA;CACC,eAAe;CACf,mBAAmB;AACpB;;;AAGA;CACC,cAAc;CACd,kBAAkB;AACnB;;;;;;AAMA;CACC,iBAAiB;AAClB;;;;;;AAMA;CACC,iBAAiB;AAClB","sourcesContent":["/*==== rtl css ====*/\n\n\n\n.appie-traffic-area.appie-traffic-area-rtl .traffic-thumb {\n\tleft: 0;\n\tright: auto;\n\ttransform: translateY(-50%) rotateY(-180deg);\n}\n\n\n.appie-traffic-area-rtl .appie-traffic-title{\n\tpadding-left: 220px;\n\tpadding-right: 0;\n}\n\n\n.appie-traffic-area-rtl .appie-traffic-service .icon{\n\tright: 0;\n\tleft: auto;\n}\n\n\n\n.appie-faq-area-rtl .faq-accrodion .accrodion .accrodion-title::after,\n.appie-faq-area-rtl .faq-accrodion .accrodion .accrodion-title::before{\n\tright: auto;\n\tleft: 30px;\n}\n\n\n.appie-project-area.appie-project-area-rtl .appie-project-box .appie-project-thumb{\n\tleft: 70px;\n\tright: auto;\n}\n\n\n\n.appie-project-area-rtl .appie-project-content .input-box input{\n\tpadding-left: 0;\n\tpadding-right: 30px;\n}\n\n\n.appie-project-area-rtl .appie-project-content .input-box button{\n\tmargin-left: 0;\n\tmargin-right: 10px;\n}\n\n\n\n\n\n.appie-header-area-rtl .appie-header-main-menu ul{\n\ttext-align: right;\n}\n\n\n\n\n\n.appie-header-area-rtl .appie-header-main-menu ul > li .sub-menu{\n\ttext-align: right;\n}\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
