import React, { useState } from "react";
import { Tabs, Tab, Grid, Icon } from "@mui/material";

const Service5 = ({data}) => {
  const [state, setState] = useState({
    value: 0,
  });

  const {
    tabs
  } = data;

  const handleChange = (event, value) => {
    setState({ value });
  };

  const { value } = state;
  let feature = tabs[value];

  return (
    <div className="section bg-light-gray" id="service5">
      <div className="container">
        {/* tab view */}
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
          scrollButtons="auto"
        >
          {tabs.map((singleTab, i) => {
            return(
              <Tab label={singleTab.title} key={i} />
            )
          })}
        </Tabs>

        <Grid
          container
          spacing={4}
          className="mt-4 fade-in"
          alignItems="center"
        >
          <Grid item lg={5} md={7} sm={12} xs={12}>
            <div className="overflow-hidden">
              <img
                className="w-full elevation-z3"
                src={feature.image.url}
                alt={feature.image.alt}
              />
            </div>
          </Grid>
          <Grid item lg={5} md={7} sm={12} xs={12}>
            <div className="service5__details">
              <h3>{feature.title}</h3>
              <p className="whitespace-pre-wrap">{feature.text}</p>

              <a className="text-primary flex items-center" href="#details">
                View details
                <Icon className="service5__link-icon">navigate_next</Icon>
              </a>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Service5;
