import React from "react";
import { Grid, Card, Icon, Button } from "@mui/material";
import { makeStyles } from '@mui/styles';
import clsx from "clsx";
import { Link } from "react-router-dom";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  card: {
    transition: "all 400ms ease-in-out",

    "& .card-icon": {
      position: "absolute",
      fontSize: 200,
      height: 200,
      width: 200,
      left: -80,
      bottom: -80,
      zIndex: 1,
      opacity: 0.24,
      transition: "all 400ms ease-in-out",
    },

    "&:hover": {
      transform: "translateY(-8px)",

      "& .card-icon": {
        transform: "translateY(-8px)",
        color: palette.secondary.main,
        opacity: 0.6,
      },
    },
  },
}));

const Service4 = ({data}) => {
  const classes = useStyles();

  const {
    title,
    subtitle,
    points,
    button_text,
    button_link
  } = data;

  return (
    <div className="section section-service4" id="service4">
      <div className="container">
        <div className="section__header">
          <h2>{title}</h2>
          <p>{subtitle}</p>
        </div>
        <div className="text-center">
          <Grid container spacing={5}>
            {points.map((card, index) => (
              <Grid item lg={6} md={6} sm={12} xs={12} key={index}>
                <Card
                  className={clsx(
                    "relative overflow-hidden card",
                    classes.card
                  )}
                >
                  <Icon className="card-icon">{card.icon_background}</Icon>
                  <div className="max-w-372 text-left p-8 ml-24">
                    <h3>{card.title}</h3>
                    <p className="m-0">{card.text}</p>
                  </div>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Button
            className="mt-8"
            size="large"
            variant="contained"
            color="secondary"
          >
            <Link to={button_link}>
              {button_text}
            </Link>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Service4;
