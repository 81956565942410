import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import HeroNews from "../News/HeroNews.jsx";

function HeroProduct({ data }) {
  return (
    <>
      <HeroNews title={data.title} breadcrumb={[{ title: data.breadcrumbs }]} />
    </>
  );
}

export default HeroProduct;
