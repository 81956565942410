import React from "react";

function HeroHomeOne({ data }) {
  const {
    welcome_message,
    title,
    description,
    download_links,
    images,
    shapes,
  } = data;

  return (
    <>
      <section className={`appie-hero-area`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="appie-hero-content">
                <span>{welcome_message}</span>
                <h1 className="appie-title">{title}</h1>
                <p>{description}</p>
                <ul>
                  {download_links.map((link, index) => (
                    <li key={index}>
                      <a href={link.url}>
                        <i className={`fab ${link.icon}`} /> {link.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="appie-hero-thumb">
                {images.map((image, index) => (
                  <div
                    key={index}
                    className={`thumb${
                      index === 1 ? "-2" : ""
                    } wow animated fadeIn${index === 1 ? "Right" : "Up"}`}
                    data-wow-duration="2000ms"
                    data-wow-delay={`${200 + index * 400}ms`}
                  >
                    <img src={image.src} alt={image.alt} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {shapes.map((shape, index) => (
          <div className={`hero-shape-${index + 1}`} key={index}>
            <img src={shape.src} alt={shape.alt} />
          </div>
        ))}
      </section>
    </>
  );
}

export default HeroHomeOne;
