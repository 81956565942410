import React from "react";

function TrafficHomeOne({ data }) {
  const services = [
    { title: data.service1Title, description: data.serviceDescription },
    { title: data.service2Title, description: data.serviceDescription },
    { title: data.service3Title, description: data.serviceDescription },
    { title: data.service4Title, description: data.serviceDescription },
  ];

  return (
    <section className={`appie-traffic-area pt-140 pb-180`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="appie-traffic-title">
              <span>{data.sectiontitle}</span>
              <h3 className="title">{data.mainTitle}</h3>
              <p>{data.description}</p>
            </div>
            <div className="row">
              {services.map((service, index) => (
                <div className="col-lg-6 col-md-6" key={index}>
                  <div className={`appie-traffic-service mb-30 item-${index + 1}`}>
                    <div className="icon">
                      <i className="fal fa-check" />
                    </div>
                    <h5 className="title">{service.title}</h5>
                    <p>{service.description}</p>
                  </div>
                </div>
              ))}
              <div className="col-lg-12">
                <div className="traffic-btn mt-50">
                  <a className="main-btn" href={data.learnMoreLink}>
                    {data.learnMoreText} <i className="fal fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="traffic-thumb">
        <img
          className="wow animated fadeInRight"
          data-wow-duration="2000ms"
          data-wow-delay="200ms"
          src={data.thumbImage.url}
          alt={data.thumbAltText}
        />
      </div>
    </section>
  );
}

export default TrafficHomeOne;
