import React from "react";

function ProjectHomeOne({ data }) {
  const { title, description, placeholder, button_text, image_url } = data;

  return (
    <>
      <section className={`appie-project-area pb-100 `}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="appie-project-box wow animated slideInUp"
                data-wow-duration="1000ms"
                data-wow-delay="0ms"
              >
                <div className="row">
                  <div className="col-lg-6">
                    <div className="appie-project-content">
                      <h3 className="title">{title}</h3>
                      <p>{description}</p>
                      <form action="#">
                        <div className="input-box mt-30">
                          <input type="text" placeholder={placeholder} />
                          <button type="button">{button_text}</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="appie-project-thumb">
                  <img src={image_url} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProjectHomeOne;
